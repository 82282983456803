<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        TOCAS sounds like we're talking about butts!
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        This is crap about VPS
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Products',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>