<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 2: Design Graphics as the Foundation for Operating Manuals
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>There is a common saying: "A picture is worth a thousand words!" In fact, this statement
        is only true if the correct picture is created to replace the thousand words.</p>

        <p>The following graphic is just one example of the many types of visuals that we integrate
        into the Operating Manual. For this example we have excerpted a part of a Unit Perspective
        Control Diagram (UPCD). The UPCD is a single poster size graphic that illustrates all
        processes and controls. In keeping with the TOCAS approach of always applying learning
        theories where we can, the design of the graphic is based on Gestalt theory to provide a
        system perspective for the entire unit. The excerpt below illustrates how the organization
        of the control description text in the Process Description Manual (on the right side) is
        parallel to - and closely follows - the layout of the UPCD graphic (on the left).</p>

        <p>The Process Description Manual text closely tracks with the UPCD graphic.</p>

        <h1>Appropriate Graphic Impact on Operator Performance</h1>
        <p>Appropriate graphics have a profound effect on Operator Performance.  This is realized
        in each of the following ways:</p>

        <ul>
          <li>Operators build common mental models to support communication amongst the members of
          the Operations Team when starting up, shutting down, optimizing, and troubleshooting their
          systems.</li>
          <li>Operators visualize the context of descriptive and procedural text in the Operating
          Manual during training.</li>
          <li>Operators quickly access detailed information in the Operating Manuals.  When the
          Operators understand and accept the parallel structure between the graphics and Operating
          Manual organization, they can intuitively navigate to the specific detailed information
          they need.</li>
          <li>Operators increase the volume of information in their long-term memory and accelerate
          the rate at which this information is committed to long-term memory.</li>
        </ul>
        
        <h1>Appropriate Graphic Impact on Operating Manual Development</h1>

        <p>The graphics also have a profound impact on the quality of writing in the Operating
        Manuals:</p>
        <ul>
          <li>When writing descriptive components, the writers focus on the graphic.  They have a
          clear vision of the scope and level of detail that needs to be provided.</li>
          <li>When writing procedures, the writers use the graphic to understand the context of the
          procedure steps, and with that understanding, they can provide greater clarity and
          completeness of the procedural instructions.</li>
        </ul>
        
        <p>You can learn more on this topic by contacting us for the following VPS Reference:</p>

        <ul>
          <li class="italix">“Introduction to Graphic Architecture for Operating Manuals”</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BP2',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>