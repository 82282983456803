<template>
  <div>
    <div class="bodyContainer">
      <div>
          <Header></Header>
      </div>
      <div>
        <router-view></router-view>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
    Header,
    Footer
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
</style>
