<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        About Our Mission: Implement World Class Performance Solutions
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>As illustrated here, the use of the TOCAS approaches result in streamlined operating
        manuals and training materials while providing a higher level of quality performance by
        client operators.</p>
        <p>The TOCAS approaches are a system of standardized approaches designed and developed by
        VPS to satisfy a wide range of performance objectives that we have recognized over the
        years as common amongst the many clients that we have previously assisted. It is the TOCAS
        aproaches that make it possible to increase the complexity of performance objectives and
        accelerate the learning process.</p>


      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/TOCAS-approach.png">
      </div>
    </div>
    <div class="row body-copy">
      <p>In each project we:</p>
      <ul>
        <li>Assess the client's performance objectives.</li>
        <li>Encourage our clients to use those TOCAS approaches that will efficiently support
        their objectives.</li>
        <li>Customize the approaches to meet specific client needs and/or existing
        standards.</li>
        <li>With this focus on performance objectives and the use of the TOCAS approaches which
        are needed to achieve them, the typical result is a streamlined documentation and training
        system that provides a higher level of quality performance as compared to traditional
        solutions.</li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Tocas',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>