<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 1: Develop a Vision of Operator Performance
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>The overall objective of this VPS Best Practice is to build a system that facilitates
        Operator growth up a well-defined Learning Curve, similar to that illustrated below for a
        typical Console Operator position at a refinery. This vision of performance is based on
        Job Task Analyses and an understanding of performance challenges from interviews with
        client Management, Supervisors, and best-performing Operators.</p>

        <p>The Learning Curve Establishes a Well-Defined Vision of Operator Performance.</p>

        <p>VPS develops this Vision of Operator Performance in two stages of analysis:</p>

        <ul>
          <li>Assimilate Initial Data. The first step of analysis is focused on understanding the
          current situation. We draft a process diagram to develop an understanding of the
          processes the Operators will be running. This provides us the perspective to analyze all
          existing material.</li>
          <li>Establish the Vision of Operator Performance:
            <ul>
              <li>Work with key members of the Operations Team to perform a Job Task Analysis; we
              catalog and document our understanding of the full range of tasks that the Operations
              Team must perform.</li>
              <li>Identify performance challenges from interviews with Management, Supervisors, and
              best-performing Operators.</li>
              <li>Draft a Learning Curve for each job position. Leverage applicable experiences as
              a starting point.</li>
            </ul>
          </li>
        </ul>
        
        <p>The details of these steps are illustrated in the diagram below:</p>

        <p>Detailed Analysis Allows for Development of the Vision of Operator Performance.</p>

        <p>You can learn more on this topic by contacting us for the following VPS References:</p>
        <ul>
          <li>Introduction to the Five Milestone Learning Curve</li>
          <li>Introduction to Job Task Analysis Process</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture" ssrc="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Bp1',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>