<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Principle: Univeral Conceptual Troubleshooting Module
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>The Total Operations Competency Assurance Solutions (TOCAS) is a system of standardized
        approaches to satisfy a wide range of performance objectives that we have recognized as
        common amongst our customers. Each approach focuses on a specific TOCAS component that
        covers a well-defined subset of competencies. Each approach includes:</p>

        <ul>
          <li>Visual Mental Model that serves as a job aid the centerpiece for training and
          facilitating use of the knowledge on the job.</li>
          <li>Description and Procedure Content to present the knowledge illustrated in the
          Mental Model.</li>
          <li>User-friendly-templated format for the descriptions or procedures associated with
          each component.</li>
          <li>Training strategy with assessments to ensure Trainees master the knowledge.</li>
          <li>Library of examples for all components to kick start development on new
          projects.</li>
          <li>Production processes and automated tools to ensure consistent quality.</li>
        </ul>

        <p>TOCAS is the result of over 20 years of evolution as we addressed customer challenges on
        dozens of projects, for many companies operating a broad variety of production processes to
        satisfy a wide range of performance objectives. Each of our projects has been a vehicle to
        create new approaches and continuously improve existing approaches.</p>

        <p>As illustrated in the figure below, VPS first analyzes the client’s specific objectives
        and then leverages TOCAS as a means to efficiently create a world class documentation and
        training system that exceeds client expectations.</p>

        <h3>TOCAS Development Sequence.</h3>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Cause-and-Effect',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>