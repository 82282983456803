<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Introduction to VPS' Best Practices
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>VPS uses a set of well-defined Best Practices to assist in the development of effective,
        streamlined, and high-quality Operating Manuals and Training Materials for our clients.</p>

        <p>Utilization of these proven Best Practices enables VPS to achieve its overall objective
        of building a system that facilitates the growth of Operators up a well-defined learning
        curve. The graphic below illustrates a learning curve for Control Room Operators in an Oil
        Production Plant or a Refinery. This is just one sample of a learning curve; similar
        learning curves can be developed for Operators in other Process Industries. By focusing on
        the learning curve, the team developing the documentation and training materials will have
        a clear purpose of what they are building; this focus helps the team decide what does and
        does not belong in these materials and what training strategies will be effective. The end
        result is a leaner and higher quality system of Operating Manuals and Training
        Materials.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BpIntro',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>