<template>
  <div class="row headerContainer">
    <div class="col-1">
      <img src="@/assets/images/vpsLogo.png" class="image floatLeft">
    </div>
    <div class="col-5">
      <div class="header-title">
        Visual Performance Solutions, Inc.
      </div>
      <div class="header-tag-line">
        "We help your workforce Visualize Solutions for better Performance!"
      </div>
    </div>

    <div class="col-1 nav-item">
      <a class="links" v-on:click="goto('Index')">Home</a>
    </div>

    <div class="col-1 nav-item">
      <div class="dropdown-menu">
        <button class="menu-btn" v-on:click="goto('About')">About VPS</button>
        <div class="menu-content">
          <a class="links-hidden" v-on:click="goto('About/AboutMission')">Our mission</a>
          <a class="links-hidden" v-on:click="goto('About/AboutChallenges')">Customers' challenges</a>
          <a class="links-hidden" v-on:click="goto('About/AboutTheories')">Learning theories</a>
          <a class="links-hidden" v-on:click="goto('About/AboutBP')">BestPractices</a>
        </div>
      </div>
    </div>

    <div class="col-1 nav-item">
      <div class="dropdown-menu">
        <button class="menu-btn" v-on:click="goto('Tocas')">Our Products</button>
        <div class="menu-content">
          <a class="links-hidden" v-on:click="goto('Products/conceptual-ts')">Conceptual Troubleshooting Training</a>
          <a class="links-hidden" v-on:click="goto('Products/ChallengeTool')">ChallengeTool</a>
          <a class="links-hidden" v-on:click="goto('Products/cause-effect')">Cause and Effects Analysis</a>
          <a class="links-hidden" v-on:click="goto('Products/self-paced')">Self Paced Training</a>
          <a class="links-hidden" v-on:click="goto('Products/graphics')">Process and Graphics</a>
          <a class="links-hidden" v-on:click="goto('Products/generic-modules')">GS-FS OTS Training</a>
          <a class="links-hidden" v-on:click="goto('Products/custom-modules')">Advanced Control</a>
          <a class="links-hidden" v-on:click="goto('Products/certification')">Refinery Operations Handbook</a>
        </div>
      </div>
    </div>

    <div class="col-1 nav-item">
      <div class="dropdown-menu">
        <button class="menu-btn" v-on:click="goto('BestPractices')">Best Practices</button>
        <div class="menu-content">
          <a class="links-hidden" v-on:click="goto('BestPractices/bpIntro')">Introduction</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp1')">BP1: Vision</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp2')">BP2: Graphics</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp3')">BP3: Operating manuals</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp4')">BP4: Training vehicles</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp5')">BP5: Training exercises</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp6')">BP6: Conceptual knowledge and skills</a>
          <a class="links-hidden" v-on:click="goto('BestPractices/Bp7')">BP7: Validated solutions</a>
        </div>
      </div>
    </div>

    <div class="col-1 nav-item">
      <a class="links"  v-on:click="goto('Contact')">Contact us</a>
    </div>
  </div>
</template>

  <script>
    export default {
      name: 'Header',
      components: {},
      data () {},
      methods:{
        goto (target) {
          var linkPath = "/" + target
				this.$router.push(linkPath)
        }
      },
      mounted: function(){}
    }
  </script>

<style></style>