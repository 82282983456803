<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 7: Leverage Validated Solutions
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>On each project, VPS leverages validated approaches from our TOCAS system of solutions.
        These approaches address the challenges that we have found to be common amongst our
        customers.</p>

        <p>Importance of Validation</p>

        <p>It is important to leverage validated solutions. Using validated solutions reaps all of
        the following benefits as compared to un validated solutions:</p>

        <ul>
          <li>High probability of Operator buy-in of the training objectives as relevant to the
          job.</li>
          <li>High quality of documentation and training materials and reduced production costs as
          a result of standardized production processes.</li>
          <li>Reduced dependence and costs of pilot implementations to get the documentation and
          training materials on target.</li>
          <li>Reduction of Operator training time required to reach full competency.</li>
        </ul>

        <p>Validation Strategy</p>

        <p>Our favorite paradigm for validation of each approach is the iterative feedback and
        adjustment process necessary to get a solution on target. As we developed each TOCAS
        approach, we successfully engaged the client organization in the validation process.</p>

        <h3>Validated Solutions Result in Successful Operator Training and On-Target ROI</h3>

        <p>This validation model is based on a broadly accepted training assessment and improvement
        model promoted by the American Society of Training and Development (ASTD).</p>

        <p>You can learn more on this topic by contacting us for the following VPS Reference:</p>

        <ul>
          <li>"Introduction to ASTD ROI Program Assessment Model"</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Bp7',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>