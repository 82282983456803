<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Process and Control Diagrams based on Gestalt Theories
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        TOCAS includes streamlined Operating Manuals and Training Materials.

        <h1>Operating Manuals</h1>

        <p>Operating Manuals contain diagrams, descriptions and procedures. All content has been
        validated to support specific performance objectives. With this focus, nice-to-know content
        can be eliminated or moved to other logical locations within the refinery documentation
        system.</p>

        <h1>Training Materials</h1>

        <p>Training Materials contain:</p>

        <ul>
          <li>Learning Guidelines that reference Operating Manuals.</li>
          <li>Exercises to challenge Operators mastery of the learning objectives.</li>
          <li>Assessments to verify learning took place.</li>
        </ul>
        
        <p>The objective of the Training Materials is to ensure that the Qualified Operator is able
        to:</p>

        <ul>
          <li>Recall and understand memorized knowledge that is prerequisite to assignment at
          his/her job position.</li>
          <li>Efficiently/intuitively navigate within the system to obtain specific information on
          an as-needed basis.</li>
          <li>Apply memorized and referenced knowledge to perform all tasks associated with his/her
          job position.</li>
          <li>Can accurately follow procedure instructions.</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Graphics',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>