<template>
  <div>
    <div class="footer-nav-bar">
    <a class="footer-link" v-on:click="goto('Index')">Home</a>
    <a class="footer-link" v-on:click="goto('About')">About VPS</a>
    <a class="footer-link" v-on:click="goto('Tocas')">Our Products</a>
    <a class="footer-link" v-on:click="goto('BestPractices')">Best Practices</a>
    <a class="footer-link" v-on:click="goto('Contact')">Contact us</a>
    </div>
    <div class="footer">&copy;Visual Performance Solutions, Inc. 2020</div>
    <div class="footer">by Chesapeake Design Build</div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Footer',
  components: {},
    data () {
    return {}
  },
  methods:{
    goto (target) {
      var linkPath = "/" + target
    this.$router.push(linkPath)
    }
  },
  mounted: function(){}
}
</script>

<style></style>