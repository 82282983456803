<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Autonomous/Self-Paced Training System
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <h1>Traditional Objectives</h1>

        <p>TOCAS decreases time to compentency to achieve traditional training objectives
        associated with Declarative (How-it-works Knowledge) and Procedural (How-to-do-it)
        Knowledge.</p>

        <h1>Conceptual Objectives</h1>

        <p>TOCAS addresses conceptual (What-to-do, Why-do-it ,When-to-do-it) Knowledge. Conceptual
        tasks are the most challenging; they include decision making, troubleshooting, and
        optimization.</p>

        <p>The traditional approach is to expect Operators to learn conceptual knowledge through
        experience. TOCAS applies two strategies:</p>

        <ul>
          <li>Apply advanced procedure writing techniques to reduce complexity of some conceptual
          tasks.</li>
          <li>Provide improved documentation and training exercises to address purely conceptual
          tasks.</li>
        </ul>

        <p>The Full comprehension and practical application of conceptual knowledge still requires
        some hands on operational experience.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Self-Paced',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>