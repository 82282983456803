<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Contacticles!
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        This is crap about VPS
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Contact',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style>
  .body-copy {
    width: 100%;
    background-color: rgba(195, 195, 195, 1);
    color: rgba(0, 0, 44, 1);
    font-family: 'Roboto';
    font-size: 1.5rem;
    text-align: left;
    padding: 1rem 1.5rem 1rem; 
  }

  .words-over-image {
    background-image: url("../assets/images/port-arthur.jpg");
    height: 45rem;
    color: rgba(191, 191, 191, 1);
    font-family: 'Roboto';
    font-size: 2.5rem;
  }

  .words-right-50 {
    width: 50%;
    text-align: right;
    padding-top: 2.5rem;
    margin-right: 2rem;
    margin-left: auto;

  }

  .words-left-55 {
    color: rgba(0, 0, 44, 1);
    font-family: 'Roboto';
    font-size: 1.5rem;
    width: 60%;
    text-align: left;
    padding: 1rem 2.5rem 1rem; 
  }

  .fit-picture {
    max-width: 100%;
    max-height: 100%;
  }
</style>