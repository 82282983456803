<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 6: Focus Special Attention on Conceptual Knowledge and Skills
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>Conceptual knowledge must be mastered to competently perform high-valued conceptual
        tasks such as:</p>

        <ul>
          <li>Decision Making</li>
          <li>Process Troubleshooting</li>
          <li>Process Optimization</li>
        </ul>
        
        <h1>Traditional Approach</h1>

        <p>Using the traditional approach, Operators mastered conceptual knowledge from practical
        experience as they learned from operational events which occurred from time-to-time, often
        very infrequently. Mastery of conceptual knowledge under these conditions required many
        years while on the job, and the level of mastery depended upon the number and frequency of
        events each Operator experienced. For complex systems, mastery traditionally required over
        10 years of on-the-job learning.</p>

        <p>Conceptual knowledge is not typically found in traditional Operating Manuals and
        conceptual skills are not typically addressed in traditional training programs.</p>

        <h1>VPS Approach</h1>

        <p>The VPS approach is designed to decrease a new Operator's time-to-competency from ten
        years (or more) to less than three years.</p>

        <h3>Use of VPS Approach Results in Decreased Time-to-Competency</h3>

        <p>As illustrated in the graphic above, our approach is two-fold:</p>

        <ul>
          <li>Reduce Volume of Conceptual Knowledge with Procedures</li>
          <ul>
            <li>Convert some conceptual tasks to procedural tasks using advanced procedure writing
            approaches.</li>
            <li>Simplify conceptual tasks with procedures that guide the Operator through a
            structured analysis and/or decision-making process.</li>
            <li>Apply advanced graphic techniques to simplify the layout of complex procedures that
            require conceptual decision-making. (Example: See Introduction to Procedure Outline
            Diagrams)</li>
          </ul>
          <li>Address Purely Conceptual Tasks with Improved Documentation and Training
          Exercises</li>
          <ul>
            <li>Create a clear vision of the specific analysis processes to be performed.</li>
            <li>Create an exercise strategy that challenges the trainee's ability to perform the
            analysis.</li>
            <li>Design documentation to support successful accomplishment of the exercises.</li>
            <li>Develop a training strategy to guide the trainee up a well-defined Learning
            Curve.</li>
            <li>On a regular basis, provide new exercises to continually challenge trainee
            capabilities until they reach full competency.</li>
          </ul>
        </ul>

        <p>You can learn more on this topic by contacting us for the following VPS References:</p>

        <ul>
          <li class="italix">"Introduction to Procedure Outline Diagrams"</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BP6',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>