<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 5: Engage Operators with Training Exercises and Assessments
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <div class="row">
          <div class="col-3">
            <p>If Confucius were involved in Operator training development, he might offer the
            following principles:</p>
            <ul>
              <li>"Tell me and I'll forget."</li>
              <li>"Show me and I may remember."</li>
              <li>"Involve me and I'll understand."</li>
            </ul>
          </div>
        </div>

        <h1>Quality Training Requires Operator Involvement to Achieve Full Understanding</h1>

        <p>As a part of its overall training success strategy, VPS strives to provide exercises that
        are very similar to the content and format of assessments. This gives the Operators a sense
        for exactly how their knowledge and skills are going to be assessed. Exercises taking place
        during training should provide the practice that Operators need to master the assessments
        events.</p>

        <p>With this clear vision, the Operators take a greater degree of ownership of their
        training. They have a better idea of where their opportunities for improvement are and seek
        the focused help they need to close on those opportunities.</p>

        <p>Assessments and exercises should insure that Operators:</p>

        <ul>
          <li>Retain required declarative knowledge in long-term memory.</li>
          <li>Perform procedural tasks given the level of detail provided in the procedures.</li>
          <li>Understanding how to apply conceptual knowledge in tasks that require analysis - these
          include optimization and troubleshooting.</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BP5',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>