<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        About Best Practices
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        As we applied learning theories and human factors into our design and development of the TOCAS approaches, we assimilated the “lessons learned” from this exercise into our Best Practices.  We have found these practices to be powerful guidelines when customizing existing approaches and/or when designing additional approaches for new challenges in a wide variety of client situations.
        The following topics are addressed in the Best Practices section:

        <ul>
          <li>BP1: Develop a Vision of Operator Performance</li>
          <li>BP2: Design Graphics as the Foundation for Operating Manuals</li>
          <li>BP3: Design Operating Manuals to Contain Three Types of Knowledge</li>
          <li>BP4: Design Training Vehicles Separate from Operating Manuals</li>
          <li>BP5: Engage Operators with Training Exercises and Assessments</li>
          <li>BP6: Focus Special Attention on Conceptual Knowledge and Skills</li>
          <li>BP7: Leverage Validated Solutions</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'AboutBP',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>