<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Our Customers' Challenges
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-10">
        <p>The following are the most common challenges that our customers have stated as
        motivation for upgrading their systems:</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-7">
        <p>The following are the most common challenges that our customers have stated as
        motivation for upgrading their systems:</p>

        <h2>1. Raising the performance level of incumbent operators</h2>

        <p>This is important given the continuous increase in the complexity of technologies being
        implemented in the process industries. In many cases, the operator performance support
        system has lagged significantly, especially with respect to documentation and training.
        This is particularly true of the Console Operator position. VPS has designed various
        approaches which help to close specific gaps. Our focus has been on:</p>

        <ul>
          <li>Improving Operator understanding of Advanced Controls.</li>
          <li>Improving Operator capabilities to Troubleshoot and Optimize Processes.</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/co-learning-curve.png">
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-10">
        <h2>2. Decreasing time-to-competency for new operators</h2>

        <p>The use of the TOCAS approaches typically reduces the amount of training time required
        by new employees by over 40% as compared to traditional approaches. This efficiency is
        important given the lean staffing strategies which exist throughout the process industry.
        We have observed that companies want - and need - to get new employees trained and on
        shifts as quickly as possible.</p>

        <p>Traditional Objectives/Topics:</p>

        <ul>
          <li>Process Safety</li>
          <li>Process Flow</li>
          <li>Daily Rounds</li>
          <li>Equipment Procedures</li>
        </ul>
        </div>
    </div>

    <div class="row body-copy">
      <div class="col-4">
        <img class="fit-picture"
          src="../../assets/images/co-experience-curve.png">
      </div>
      <div class="col-8">
        <h2>3. Capturing the knowledge of expert operators before they retire</h2>

        <p>The process industry is undergoing a significant change in demographics as expert
        operators with upwards of 20 years of experience are retiring. The TOCAS approaches include
        efficient strategies to capture the knowledge of these experts and to pass it down to the
        new operators entering the organization.</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-10">
        <h2>4. Compliance-Based Objectives</h2>

        <p>In many cases, documentation and training projects have been started with the overriding
        client objective being to achieve compliance with government mandated documentation and
        training requirements. In almost all of these cases, VPS has demonstrated how our solutions
        address not only the objective associated with compliance, but also the challenges
        associated with one or more of the first three performance-related objectives described
        above. Thus, the ultimate results achieved go far beyond compliance issues themselves, and
        also provide clients with a much greater return on their investment.</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'AboutChallenges',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>