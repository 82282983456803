<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Our Adaptation on Learning Theory
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>The TOCAS approaches are based on the use of sound learning theories and broadly
        accepted human factors. These items have provided VPS with insight on how the human mind
        assimilates, processes, retains, and applies knowledge. In particular, the Gestalt Theory
        of Learning and Bloom's Taxonomy have had the most significant impact on our design.</p>

        <h1>Gestalt Theory of Learning</h1>

        <p>From Wikipedia:</p>

        <ul>
          <li>“Gestalt psychology" is a theory of mind and brain that proposes that the operational
          principle of the brain is holistic, parallel, and analog, with self-organizing
          tendencies.</li>
          <li>The Gestalt effect refers to the form-forming capability of our senses, particularly
          with respect to the visual recognition of figures and whole forms instead of just a
          collection of simple lines and curves.”</li>
        </ul>

        <p>With the understanding of these principles, VPS has applied this theory in several
        ways:</p>

        <ul>
          <li>We provide a system of diagrams with each diagram designed to provide a system
          perspective at a specific level-of-detail.</li>
          <li>We describe systems of knowledge using a deductive writing style; each description
          component begins with an introductory sentence or paragraph to frame the detailed
          information to follow.</li>
          <li>We incorporate description and procedure formats with an embedded outline to help the
          reader maintain a perspective of the overall organization of the content.</li>
        </ul>

        <h1>Bloom's Taxonomy</h1>
        <p>Bloom’s Taxonomy was originally proposed by Benjamin Bloom, et al, in 1956.  Benjamin
        Bloom was an educational psychologist at the University of Chicago.  His basic premise is
        that learning is hierarchical and that effective training starts with low level learning
        objectives and progresses through levels up to that of an expert. The knowledge and skills
        of each level is a prerequisite to acquiring knowledge and skills at the next level.</p>

        <h1>Operator Learning Curve</h1>
        <p>TOCAS approaches are designed with a focus on a learning curve such as that shown below
        for each operating position. The curve illustrates the specific competencies that the
        Operator must master in the logical sequence in which they will be covered in the training.
        This serves as a centerpiece during development of the materials and implementation of the
        training.</p>

        <h1>Basic Operator Training</h1>
        <p>The learning curve provided above is focused on a mastery of knowledge and skills
        associated with the specific equipment and processes that the Operator will be working
        with. In practice, there is also a Milestone 0 that addresses basic operator training which
        covers topics applicable to process operations in general.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'AboutTheories',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>