<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 4: Design Training Vehicles Separate from Operating Manuals
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>As illustrated in the graphic below, the Operating Manuals are On-the-Job references in 
        the Training Program.</p>

        <h3>A Quality Training Program Contains Both Training Manuals and Operating Manuals.</h3>

        <p>The Training Program may also include a Training Manual for each position and a variety
        of other Training-Specific Vehicles that are separate from the Operating Manuals. The
        Training-Specific Vehicles do not include any operational descriptive or procedural
        information that the Operator would be expected to reference on the job. The Training
        Workbooks include learning objectives, learning guidelines, exercises, and assessments that
        are designed to help Operators systematically climb up the Learning Curve.</p>

        <h1>General Training Approaches</h1>

        <p>The training approaches facilitated by the Training-Specific Vehicles are segregated
        into three general training objective categories, each focused on a specific scope of
        skills and knowledge.</p>

        <h3>Category 1: Basic Skills and Memory-Resident Knowledge to Perform Routine Tasks</h3>

        <p>This training objective category focuses on mastery of declarative and procedural
        knowledge that the Operators should be able to readily recall from memory.</p>

        <p>The training activities for this category require the operators to first learn the
        material through reading assignments, then repeatedly recall the knowledge from memory and
        use it in various types of exercises and demonstrations. Each time an Operator recalls
        knowledge, it becomes more firmly implanted in long-term memory.</p>

        <h3>Category 2: Basic Skills and Referenced Knowledge to Perform Non-Routine or Complex
        Tasks</h3>

        <p>This training objective category focuses on mastery of detailed declarative and
        procedural knowledge the Operators do not need to have in long-term memory. They do,
        however, require easy access to the reference material when performing the tasks.</p>

        <p>The training activities for this category require the Operator to demonstrate the
        following for any specific operational situation and task that falls into this
        category:</p>

        <ul>
          <li>Intuitively and efficiently navigate to the specific knowledge in the Operating
          Manuals.</li>
          <li>Effective apply the referenced knowledge to competently perform the task.</li>
        </ul>

        <h3>Category 3: Advanced Skills and Knowledge to Perform Conceptual Tasks</h3>

        <p>Conceptual knowledge is needed by Operators for troubleshooting, optimizing, and
        decision-making tasks. The volume of conceptual knowledge that must be mastered for these
        types of tasks is typically large. The primary users of this knowledge are the Console
        Operator and Supervisor. This type of knowledge can only be mastered by experiencing a
        multitude of learning events over an extended period of time.</p>

        <p>The documentation and training for conceptual tasks is a complex undertaking. Competence
        in these conceptual tasks is very important to operations integrity. Therefore, successful
        documentation and training for these tasks yields a tremendous return-on-investment. This
        is why VPS devotes a separate Best Practice to this topic [See Best Practice 6].</p>

        <p>You can learn more on this topic by contacting us for the following VPS References:</p>

        <ul>
          <li>"Introduction to Five-Milestone Training"</li>
          <li>"Introduction to the Training Workbook"</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BP4',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>