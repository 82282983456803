<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Solutions designed to address customers' most challenging performance objectives.
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        Visual Performance Solutions, Inc. (VPS) develops training systems for engineers and
        operators in oil refining, founded in 2002, providing world-class performance improvement
        solutions to companies in the process industries. VPS establishes a partnership with your
        employees to customize visual solutions that decrease training time, increase retention,
        and improve performance. Using these solutions accelerates time to competency of complex
        systems and processes. A system of streamlined documentation combined with mentally
        digestable graphical representations designed to facilitate building effective knowledge
        and skills.
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Index',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>