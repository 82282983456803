<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Refinery Operations Handbook
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>The following is the history – in his own words – of how VPS Founder, Warren Averill,
        developed the concepts and methodologies that led to the creation of TOCAS.</p>

        <p>“About 29 years ago, I started studying methods which could accelerate the learning
        process and improve comprehension. This was founded on Gestalt Theory of Learning and a
        study of comprehension theories associated with speed reading. My general direction was to
        test theories about how visual representations and graphics are effective mechanisms to
        help improve the ability of people to assimilate, comprehend, recall, and develop
        analytical solution trategies based on large amounts of technical information.</p>

        <a v-on:click="goto('Tocas/Whitepaper')">CLICK HERE TO LEARN MORE</a>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Ref-Ops-Handbook',
  components: {},
  data () {
  return {}
  },
  methods:{
    goto (target) {
      var linkPath = "/" + target
    this.$router.push(linkPath)
    }
  },
  mounted: function(){}
}
</script>

<style></style>