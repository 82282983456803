<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Benefits of Advance Control Training
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae corrupti ea dolor sit
          aliquid molestiae delectus magni necessitatibus asperiores. Dolore possimus, explicabo
          sint numquam sapiente ex delectus doloribus laboriosam? Accusamus?</p>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, nemo similique, omnis sed
          dolorum soluta distinctio saepe sequi consequuntur reprehenderit eveniet in, iure dolore
          error! Eveniet harum error perferendis voluptate!</p>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident voluptatem dolore
          repudiandae recusandae, animi facere. Neque, blanditiis! Temporibus excepturi aliquid,
          consequatur officiis, enim dolorem dolore provident ut voluptatem, omnis est.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Advanced-Control',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>