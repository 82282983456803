import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/Index.vue'

import About from '../views/About.vue'
import AboutMission from '../views/About/aboutMission.vue'
import AboutChallenges from '../views/About/aboutChallenges.vue'
import AboutTheories from '../views/About/aboutTheories.vue'
import AboutBP from '../views/About/aboutBP.vue'

import Products from '../views/Products.vue'
import ConceptualTS from '../views/Products/conceptual-ts.vue'
import ChallengeTool from '../views/Products/challengetool.vue'
import CauseEffect from '../views/Products/cause-and-effect.vue'
import SelfPaced from '../views/Products/self-paced.vue'
import Graphics from '../views/Products/graphics.vue'
import GsFsOtsTraining from '../views/Products/gs-fs-ots-training.vue'
import RefOpsHandbook from '../views/Products/ref-ops-handbook.vue'
import AdvancedControl from '../views/Products/advanced-control.vue'

import BestPractices from '../views/BestPractices.vue'
import BpIntro from '../views/BestPractices/bpIntro.vue'
import Bp1 from '../views/BestPractices/bp1-vision.vue'
import Bp2 from '../views/BestPractices/bp2-graphics.vue'
import Bp3 from '../views/BestPractices/bp3-op-manuals.vue'
import Bp4 from '../views/BestPractices/bp4-vehicles.vue'
import Bp5 from '../views/BestPractices/bp5-exercises.vue'
import Bp6 from '../views/BestPractices/bp6-knowledge.vue'
import Bp7 from '../views/BestPractices/bp7-validation.vue'

import Contact from '../views/Contact.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/index',
      name: 'Index',
      component: Index
    },

    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/about/aboutMission',
      name: 'AboutMission',
      component: AboutMission
    },
    {
      path: '/about/aboutChallenges',
      name: 'AboutChallenges',
      component: AboutChallenges
    },
    {
      path: '/about/aboutTheories',
      name: 'AboutTheories',
      component: AboutTheories
    },
    {
      path: '/about/aboutBP',
      name: 'AboutBP',
      component: AboutBP
    },

    {
      path: '/products',
      name: 'Products',
      component: Products
    },
    {
      path: '/products/conceptual-ts',
      name: 'ConceptualTS',
      component: ConceptualTS
    },
    {
      path: '/products/ChallengeTool',
      name: 'ChallengeTool',
      component: ChallengeTool
    },
    {
      path: '/products/cause-effect',
      name: 'CauseEffect',
      component: CauseEffect
    },
    {
      path: '/products/self-paced',
      name: 'Self-Paced',
      component: SelfPaced
    },
    {
      path: '/products/graphics',
      name: 'Graphics',
      component: Graphics
    },
    {
      path: '/products/gs-fs-ots-training',
      name: 'GS-FS-OTS-Training',
      component: GsFsOtsTraining
    },
    {
      path: '/products/ref-ops-handbook',
      name: 'Ref-Ops-Handbook',
      component: RefOpsHandbook
    },
    {
      path: '/products/advanced-control',
      name: 'Advanced-Control',
      component: AdvancedControl
    },

    {
      path: '/bestpractices',
      name: 'BestPractices',
      component: BestPractices
    },
    {
      path: '/bestpractices/bpIntro',
      name: 'BpIntro',
      component: BpIntro
    },
    {
      path: '/bestpractices/bp1',
      name: 'Bp1',
      component: Bp1
    },
    {
      path: '/bestpractices/bp2',
      name: 'Bp2',
      component: Bp2
    },
    {
      path: '/bestpractices/bp3',
      name: 'Bp3',
      component: Bp3
    },
    {
      path: '/bestpractices/bp4',
      name: 'Bp4',
      component: Bp4
    },
    {
      path: '/bestpractices/bp5',
      name: 'Bp5',
      component: Bp5
    },
    {
      path: '/bestpractices/bp6',
      name: 'Bp6',
      component: Bp6
    },
    {
      path: '/bestpractices/bp7',
      name: 'Bp7',
      component: Bp7
    },

    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
  ]
})