<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Conceptual Troubleshooting Training with ChallengeTool
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <h1>Learning Curve</h1>

        <p>TOCAS focuses on a well defined learning curve that represents the full range of
        competencies for each job position.</p>

        <p>Each curve is founded on a comprehensive performance vision that we developed while
        working on projects across the Petrochemical Industry. In all of those projects we:</p>

        <ul>
          <li>Performed Job Task Analyses.</li>
          <li>Captured input and feedback from Managers, Supervisors, and best-performing Operators.</li>
        </ul>

        <h1>Learning Curve</h1>

        <p>As compared to traditional approaches, documentation and training systems based on TOCAS
        are:</p>

        <ul>
          <li>Far simpler to create and deliver.</li>
          <li>More sophisticated in organization and presentation.</li>
          <li>More comprehensive over the full range of operational performance objectives.</li>
          <li>More focused on the competencies that result in greater Return on Investment (ROI).</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ChallengeTool',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>