<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Best Practice 3: Design Operating Manuals to Contain Three Types of Knowledge
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-7">
        <p>Going into projects, VPS has found information in memos, operational notes, personal
        notebooks, and training materials that should have been integrated into the Operating
        Manuals. At the same time, VPS has always stressed that the Operating Manuals should not
        include any training content such as training guidelines or exercises.</p>

        <div class="row">
          <div class="col-2"></div>
          <div class="col-2">
            <h2>Design Requirements</h2>
          </div>
          <div class="col-5">
            To facilitate the objective of meeting Design Requirements, the Operating Manuals must
            be designed to have a logical place for each type of information. A starting point for
            this design is to insure that the Operating Manuals will accommodate the three
            categories of operational knowledge.
          </div>
          <div class="col-2"></div>
        </div>

        <h1>Three Categories of Operational Knowledge</h1>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-2">
            <h2>Declarative Knowledge</h2>
          </div>
          <div class="col-5">
            This is how-it-works knowledge. This includes a basic description of flow through the
            process, and the basic function of all equipment and controls.
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-2">
            <h2>Procedural Knowledge</h2>
          </div>
          <div class="col-5">
            This is how-to-do-it knowledge. This knowledge can be provided in the form of standard
            operating procedures and work aids. It includes step-by-step instructions on how to
            perform tasks.
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row">
          <div class="col-2"></div>
          <div class="col-2">
            <h2>Conceptual Knowledge</h2>
          </div>
          <div class="col-5">
            This is what-to-do, why-do-it, and when-to-do-it knowledge. The critical content
            includes technical details on chemistry, relationships among key parameters and
            strategies for all control systems. Operators with this expert knowledge are good
            troubleshooters and optimizers. They can systematically analyze symptoms and quickly
            identify root causes when problems occur. They can also mentally simulate optimization
            approaches and determine which will yield the best results.
          </div>
          <div class="col-2"></div>
        </div>

        <h1>Sample Implementation</h1>

        <h2>The figure below illustrates a sample Operating Manual design that is consistent with
        this Best Practice</h2>

        <h3>Operating Manuals Containing Three Types of Operational Knowledge.</h3>

        <h2>You can learn more on this topic by contacting us for the following VPS
        References:</h2>
        <ul>
          <li>"The 3 R's Model for Effective Knowledge Management"</li>
          <li>"Introduction to Process Description Manual"</li>
          <li>"Introduction to Standard Operating Procedures Manual"</li>
        </ul>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/skills.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'BP3',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>