<template>
  <div class="body">
    <div class="words-over-image">
      <div class="words-right-50">
        Conceptual Troubleshooting Training for Refinery Operators
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <p>Throughout the refining industry, the average years of experience for Console Operators
        is decreasing as large numbers of very experienced Console Operators are retiring1. Most
        Operations Managers and Supervisors agree that the highest-value Console Operator
        competencies that are being lost in the crew change are associated with:</p>

        <ul>
          <li><span class="underline">Understanding</span> process and control concepts.</li>
          <li>Ability to <span class="underline">troubleshoot</span> process and control
          malfunctions.</li>
        </ul>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-7">
        <p>To close this competency gap requires a new and better way of training troubleshooting
        skills and providing the underlying prerequisite Conceptual Knowledge. As illustrated in
        Figure 1, the goal of the program is “Conceptual Troubleshooting” competency because it is
        the most challenging performance objective.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/fig-1-journey-to-conceptual-troubleshooting-competency.png">
        <figcaption>Figure 1. Journey to Conceptual Troubleshooting Competency</figcaption>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <p>The Conceptual Knowledge and skills acquired in the journey to that goal has significant
        added value beyond troubleshooting. Operators learn how to leverage that understanding to
        predict (mentally simulate) process responses to setpoint adjustments; they will be
        mentally engaged when optimizing operation and ensure normal operation when performing
        critical procedures – mode changes, startups, and shutdowns.</p>

        <p>The design basis for a performance-based training system should always start with a
        performance vision. Figure 2 illustrates a universal performance vision that is consistent
        with the analysis strategies employed by expert conceptual troubleshooters regardless of
        the industries where they work.</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-5">
        <p>Conceptual Troubleshooting is the most difficult to master but it is the most powerful.
        With VPS Conceptual Troubleshooting training program, those Operators with a conceptual
        analysis aptitude can attain a high level of troubleshooting competency within months. In
        contrast, employing traditional on-the-job training approaches require many years.</p>

        <p>It is important to realize that the Conceptual Knowledge that is prerequisite to
        Conceptual Troubleshooting is the most valuable but, it is rarely addressed in traditional
        training and documentation systems.<sup>3</sup></p>
      </div>

      <div class="col-7">
        <img class="fit-picture"
          src="../../assets/images/fig-2-universal-conceptual-troubleshooting-model.png">
        <figcaption>Figure 2. Universal Conceptual Troubleshooting Model<sup>2</sup></figcaption>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <h1>Rule-Based vs. Conceptual Troubleshooting</h1>
        <p>To implement a Conceptual Troubleshooting approach as a standard, a cultural
        transformation from rule-based to conceptual-based thinking must take place.</p>

        <br>

        <h2 class="italix align-center">RuleBased Troubleshooting<sup>2</sup></h2>

        <p>The troubleshooting procedure of Table 1 is the simplest structure to illustrate
        rule-based troubleshooting process. When Operators detect an off-normal process condition,
        they:</p>
      </div>
    </div>
    <div class="row body-copy">
      <div class="col-6">
        <ul>
          <li>Develop a list of observed symptoms that characterize the off-normal condition.</li>
          <li>Scan the list of symptom combinations in the troubleshooting table to locate the
          combination that exactly matches the observed symptoms list.</li>
          <li>Conclude that the corresponding root cause in the table is the problem.</li>
          <li>Perform the corrective action procedure steps.</li>
        </ul>
        
      </div>

      <div class="col-6">
        <p class="table-title">Table 1. Troubleshooting Procedure Tabular Structure</p>
        <table class="table table-bordered table-dark">
          <thead>
            <tr>
              <th scope="col">If</th>
              <th scope="col">and If</th>
              <th scope="col">Then root cause is</th>
              <th scope="col">Corrective action is</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Symptom 1</td>
              <td>Symptom 2</td>
              <td>Malfunction 1</td>
              <td>Procedure steps...</td>
            </tr>
            <tr>
              <td>Symptom 1</td>
              <td>Symptom 3</td>
              <td>Malfunction 2</td>
              <td>Procedure steps...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <p>Rule-based troubleshooting procedures are practical for individual pieces of
        equipment and for simple process systems because there are:</p>

        <ul>
          <li>A <span class="underline">small</span> number of malfunctions that the Operator
          needs to recognize.</li>
          <li>A <span class="underline">small</span> number of symptoms that the Operator should
          consider in the search for the root cause.</li>
        </ul>

        <p>Traditionally, most competent troubleshooters in refineries have been implementing
        rule-based troubleshooting strategies on complex refinery processes. Each of those
        troubleshooters has the equivalent of a massive troubleshooting procedure table in
        long-term memory. That knowledge was acquired over a very long period of time (typically
        more than 10 years). The knowledge was acquired from many opportunities to interact with
        the control systems and work through a multitude of malfunction events on the job. For a
        complex refinery process, the knowledge to support a rule-based troubleshooting approach is
        massive and there are no efficient formal training strategies to develop that knowledge
        base. The other disadvantages of rule-based troubleshooting are:</p>

        <ul>
          <li>It does not prepare Operators to troubleshoot malfunctions that they have never
          seen.</li>
          <li>It is not transferrable to other processes and to new control strategies implemented
          on their processes.</li>
        </ul>

        <br>

        <h1>Essential Components of a Conceptual Troubleshooting Training Program</h1>

        <p>For complex processes, there are:</p>

        <ul>
          <li>A <span class="underline">large</span> number of malfuntions that the Operator needs
          to recognize.</li>
          <li> A <span class="underline">large</span> number of sumptoms that the Operator should
          consider in the search for the root cause.</li>
        </ul>

        <p>In these cases, Conceptual Troubleshooting is the most effective approach. The following
        are key components of each training module.</p>

        <br>

        <h2 class="italix align-center">Unit Process Control Diagram<sup>4</sup></h2>

        <p>This facilitates a contextual understanding of the overall process and control system.
        Key aspects include:</p>

        <ul>
          <li>User friendly and easy to interpret.</li>
          <li>Provides sufficient level of detail to facilitate conceptual troubleshooting.</li>
          <li>Very readable when printed on 13”X19” sheet.</li>
        </ul>

        <br>

        <h2 class="italix align-center">Large Exercise Library</h2>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-7">
        <p>Examples and exercises are the most effective vehicles to translate the general vision
        of performance into explicit representations of the ultimate on-the-job performance
        objectives. As illustrated in Figure 3, exercises are the primary vehicles to engage
        Operators in discussions, practice, and opportunities to mentor (teach) peers. This
        engagement strategy is the most effective way to promote conceptual knowledge acquisition,
        understanding, long- term memory retention, and ability to apply it on the job.</p>
      </div>
      <div class="col-5">
        <img class="fit-picture"
          src="../../assets/images/fig-3-effectiveness-of-various-training-activities.png">
        <figcaption>Figure 3. Effectiveness of Various Training Activities</figcaption>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">

        <br>

        <h2 class="italix align-center">Simple-to-Complex Curriculum</h2>

        <p>The training curriculum progresses in distinct stages transitioning from simple to
        complex such as in Figure 4 <span class="italix">Training Module E Process and Control
        Fundamentals</span>. This is the first module in the curriculum. It establishes a common
        foundation using generic materials. Subsequent training modules for specific refinery
        process units employ the same lessons sequence of topics as shown for Topic 3 – Chemistry,
        Baseline Control, Cause and Effects, and Troubleshooting.</p>

        <br>

        <h2 class="italix align-center">Process and Control Description Manual</h2>

        <p>The primary focus of this manual is on the prerequisite Conceptual Knowledge that
        Console Operators need to perform Conceptual Troubleshooting. A separate chapter is
        provided to facilitate successful completion of exercises for each of the four lessons.
        Each chapter includes many analysis examples to demonstrate how the concepts apply in
        operational scenarios. Each example is the basis for up to 4 exercises in the exercise
        library. Each of the first three chapters provides prerequisite knowledge needed to develop
        contextual understanding of the examples in subsequent chapters.</p>

        <p><span class="underline">Chapter 4 Chemistry.</span> The level of detail is set to
        provide a meaningful chemistry vocabulary that facilitates communications among the
        operations team members in conceptual analysis discussions.</p>

        <p><span class="underline">Chapter 5 Baseline Control.</span> These controls implement the
        standard low-level control strategies found in all process unit operations. This chapter
        provides a detailed description of each control loop that appears on the UPCD and may also
        include an example operational scenario to demonstrate how chemistry concepts apply.</p>

        <p><span class="underline">Chapter 6 Cause and Effects.</span> For each central process in
        the unit, this describes the relationships between each manipulated variable (MV) and the
        controlled variables (CVs). A MV is typically a control loop setpoint. The CVs are
        typically product quality indicators such as analyzer readings or operational constraints
        such as the range of acceptable control loop outputs. An analysis strategy is provided to
        guide systematic approach employed in the examples and exercises.</p>

        <p><span class="underline">Chapter 7 Troubleshooting.</span> This describes a Functional
        Troubleshooting Method that is consistent with universal troubleshooting model but
        customized for refinery processes. A separate example demonstrates how to apply the method
        to troubleshoot each process in the unit.</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-6">

        <br>

        <h2 class="italix align-center">ChallengeTool&trade; Simulator Lite Exercise
        Application</h2>

        <p>As illustrated in Figure 5, <span class="italix">ChallengeTool</span> is an internet
        browser-based conceptual exercise and assessment application that delivers a large volume
        of exercises that challenge the Operator’s mastery of the conceptual knowledge and
        how to apply it in conceptual operational scenarios.<sup>4</sup> Over 75% of training time
        associated with conceptual knowledge learning process should be spent working through
        exercises.</p>
      </div>

      <div class="col-6">
        <img class="fit-picture"
          src="../../assets/images/fig-5-challengetool-configuration.png">
        <figcaption>Figure 5. ChallengeTool&trade; Configuration<sup>2</sup></figcaption>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-6">
        <img class="fit-picture"
          src="../../assets/images/fig-4-simple-to-complex-curriculum.png">
        <figcaption>Figure 4. Simple-to-Complex Curriculum<sup>6</sup></figcaption>
      </div>

      <div class="col-6">
        <p>The application is dynamically designed to accommodate any refinery process unit without
        any modifications to the application code. For each refinery unit, the database content
        includes a customized syllabus and exercise library. ChallengeTool also provides access
        to description manual content associated with each lesson.</p>

        <br>

        <h2 class="italix align-center">Assessments</h2>

        <p>What is not assessed is rarely achieved. Standardized assessments are needed to measure
        the degree that learning is taking place. Assessments are derived from the training
        exercises. Assessments can be used for certification or qualification criteria. Just as
        important, analysis of assessment results provides insights on opportunities to improve
        training material.</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <h1>What if Exercise Format</h1>

        <p>“What if....” exercises such as depicted below are vehicles to challenge the Operator’s
        understanding of Chemistry, Baseline Control, and Cause-and-Effects concepts. The Operator
        demonstrates mastery of the concepts by completing mental simulation statements; the
        Operator is expected to enter symbols and make selections like those indicated in red.</p>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-6">
        <p class="italix">Example Exercise: What if the measurement instrument for PC41 D-2
        Degassing Surge Drum overhead pressure is drifting low?</p>

        <p>PC41 Response: Incremental &#8681; PC41.PV (due to false reading)</p>
        <p>&#8680; PC41.PV <span class="underline">greater than</span>/<span
        class="red-font underline">less than</span>/<span class="underline">equal to</span> SP</p>
        <p>&#8680; PC41.OP to control valve <span class="red-font">&#8681;</span> PC41.PV
        <span class="red-font">&#8679;</span> to SP</p>
        <p>&#8680; actual pressure is <span class="red-font">greater than</span>/<span
        class="underline">less than</span>/<span class="underline">equal to</span> PC41.SP</p>
        <br>

        <p>FC21 Response: &#8679; actual pressure in the drum</p>
        <p>&#8680; back pressure at the drum inlet <span class="red-font">&#8679;</span></p>
        <p>&#8680; differential pressure across FC21 control valve
        <span class="red-font">&#8681;</span></p>
        <p>&#8680; FC21.PV is <span class="underline">greater than</span>/<span
        class="red-font">less than</span>/<span class="underline">equal to</span> PC41.SP</p>
        <p>&#8680; FC21.OP <span class="red-font">&#8679;</span> &#8680; FC21.PV
        <span class="red-font">&#8679;</span> to SP</p>
        <br>

        <p>LC59 Response: &#8679; actual pressure in the drum</p>
        <p>&#8680; driving force of liquid at the drum inlet <span class="red-font">&#8679;</span>
        <span class="red-font">&#8679;</span></p>
        <p>&#8680; differential pressure across LC59 control valve
        <span class="red-font">&#8679;</span></p>
        <p>&#8680; FI29 drum outlet flow <span class="red-font">&#8679;</span> &#8680; LC59.PV drum
        level <span class="red-font">&#8681;</span></p>
        <p>&#8680; LC59.PV is <span class="underline">greater than</span>/<span
        class="red-font">less than</span>/<span class="underline">equal to</span> SP</p>
        <p>&#8680; LC59.OP &#8681; &#8680; LC59.PV &#8679; to SP and FI29 &#8679; to initial value
        (value before the pressure increase)</p>

      </div>

      <div class="col-6">
        <img class="fit-picture"
          src="../../assets/images/degassing-surge-drum.png">
        <figcaption>The response footprint on the control diagram provides a vision of the overall
        impact on the process and control system.</figcaption>
      </div>
    </div>

    <div class="row body-copy">
      <div class="col-12">
        <h1>Highly Competent Operators Contribute to the Bottom Line</h1>

        <p>The following are common refinery business objectives. Implementing this program is an
        effective means for accomplishing these. The examples below are based on a Crude
        Distillation Unit with 100 KBD capacity operating at 90% utilization and a gross refinery
        margin of $8 per barrel.</p>

        <p><span class="underline">Run Full.</span> Operators continuously maximize throughput
        while maintaining products on specification. A key optimization strategy in most cases is
        to drive unit processes to constraints. Example: A 1 KBD increase in feed rate results in
        $8,000 per day of additional revenue which translates to $2.9M per year profit.</p>

        <p><span class="underline">Run Long.</span> Opoerators maximize time between turnarounds by
        continuously minimizing the progression of degradation factors that drive turnaround
        frequency. Examples of these factors include corrosion, erosion, fouling, and catalyst
        deactivation. Example: For a typical cost of $30M for a turnaround every 36 month, each
        month added to the duration between turnarounds can translate to $833K additional
        revenue.</p>

        <p><span class="underline">Run Reliably.</span> Operators troubleshoot and resolve issues
        to prevent unscheduled shutdowns. If a shutdown is necessary, highly competent Operators
        minimize the degree of the shutdown; if prudent, they go to minimum flow or idle modes
        rather than a full shutdown. This decreases restart complexity and significantly reduces
        lost opportunity cost (LOC). Example: By going to an idle mode instead of a full shutdown
        can decrease lost opportunity cost for the event by over $800K.</p>
        <br>

        <p class="references">References:</p>

        <ol>
          <li class="references italix">Rhodes, M., “Overcoming the challenges of the ‘great crew
          change’,” Hydrocarbon Processing, June 2015.</li>
          <li class="references italix">Johnson, S. D., “Training technical troubleshooters,” Technical &
          Skills Training, October 1991.</li>
          <li class="references italix">Hall, E. P., S. P. Gott and R. A. Pokorny, A Procedural Guide to
          Cognitive Task Analysis: The PARI Methodology, Manpower and Personnel Division, Brooks
          AFB, Texas, November 1995.</li>
          <li class="references italix">Wallace, D. S., S. W. C. West, A. Ware and D. F. Dansereau, “The
          effect of knowledge maps that incorporate Gestalt principles on learning, The Journal of
          Education, September 1998.</li>
          <li class="references italix">Roffel, J. and M. Ross, “Leveling up: Progress from operator
          training to competency management,” AFPM Annual Meeting, San Francisco, California, March
          2016.</li>
          <li class="references italix">Eisner, E. W., “A look into Benjamin Bloom’s life work,”
          Prospects: The quarterly review of comparative education, Vol. 30, No. 3, Unesco, Paris,
          September 2000.</li>
        </ol>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
//Example import below: 
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Conceptual-TS',
  components: {},
  data () {
  return {}
  },
  methods:{},
  mounted: function(){}
}
</script>

<style></style>